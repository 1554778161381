<template>
  <b-container fluid>
    <b-row>
      <div class="iq-top-navbar">
        <div class="iq-navbar-custom">
          <nav class="navbar navbar-expand-lg navbar-light p-0">
            <div class="iq-navbar-logo d-flex justify-content-between">
                <img src="../assets_gide/img/logo/GideWhiteNRed.jpeg" class="img-fluid navBarLogo mt-3" alt="logo">
            </div>
          </nav>
        </div>
      </div>

      <b-col md="8" lg="8" class="" v-if="operatingsystem == 'web'">
        <iq-card class="p-4 banner-text-left-card">
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <img :src="eventDetail.event_img" style="width:100%" />
            <!-- Event Description -->
            <div class="col-12 mt-4">
              <span id="contactUsV1EventDescId" v-html="eventDetail.event_desc"></span>
            </div><!-- Event Description -->
          </template>
        </iq-card>
      </b-col>

      <b-col md="8" lg="8" class="" v-if="operatingsystem !== 'web'">
        <iq-card class="ban_card">
          <template v-slot:headerTitle>
          </template>
           <img v-if="!propOpenedInModal" :src="eventDetail.event_img" style="width:100%" />
        </iq-card>
      </b-col>

      <b-col lg="4" style='background-color:#fff'>
        <div class="p-3">
          <h3 class="mb-0">
            Sign Up
          </h3>
          <p>
            Enter your email address to sign up.
          </p>
          <form class="mt-4">
            <div class="form-group">
              <label for="exampleInputEmail2">Email address</label>
              <input v-model="userEmail" type="email" class="form-control mb-0" id="exampleInputEmail2" placeholder="Enter email">
            </div>
            <div class="d-inline-block w-100">
              <button type="button" class="btn btn-primary float-right" @click="register">
                Register
              </button>
            </div>
            <div class="sign-info">
              <span class="dark-color d-inline-block line-height-2">Already Have An Account ? <router-link :to="{ path: 'login'}">Sign In</router-link></span>
              <ul class="iq-social-media mt-4">
                <li>
                  <a href="https://www.facebook.com/highschoolmomsgroup" target="_blank"><i class="ri-facebook-box-line"></i></a>
                </li>
                <li>
                  <a href="https://twitter.com/hsminace" target="_blank"><i class="ri-twitter-line"></i></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/highschoolmoms" target="_blank"><i class="ri-instagram-line"></i></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/company/highschoolmoms/" target="_blank"><i class="ri-linkedin-line"></i></a>
                </li>
              </ul>
            </div>
          </form>
          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg">
            </div>
          </b-toast>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<style>
  @import url("../assets/css/custom.css");
  @import url("../assets/css/PriceSlider.css");
</style>
<script>
import { User } from "../FackApi/api/user.js"
import ApiResponse from "../Utils/apiResponse.js"

export default {
  name: "Register",
  data: () => ({
    showToast: false,
    toastMsg: null,
    toastVariant: "default",
    toastTitle: "Registration Response",
    userEmail: "",
    userName: "",
    showPasswordField: false,
    operatingsystem: window.__DEVICE_OS__,
    orgData: {
      org_name: "GIDE.AI",
      org_desc: "ABCD",
      org_logo: "../assets_gide/img/logo/GideWhiteNRed.jpeg",
      org_cover_img: "ABCD"
    },
    eventDetail: {
      event_name: "GIDE",
      event_img: "https://gide-assets-prod.s3.amazonaws.com/EVENT/EVEcff7a6d503ee443b86553f529683d4e3/8a48d740-2ee6-11ef-864f-03eb2ac38166.png",
      event_desc: "Join HSM Connect, a virtual Career Counseling & University Fair scheduled on July 20 & 21, where students and parents from different regions can connect & interact with 20+ universities from across the globe. Students & parents will get to learn about academic programs, scholarships, campus life, entry requirements, timelines & much more."
    }
  }),
  mounted () {
    if (this.userData && this.userData.user_email) {
      this.userEmail = this.userData.user_email
      this.show_user_email = true
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    async register () {
      let userObj = {
        "user_email": this.userEmail
      }
      let userRegisterResp = await User.userRegister(this, userObj)
      await ApiResponse.responseMessageDisplay(this, userRegisterResp)
      if (!userRegisterResp.resp_status) {
        this.toastVariant = "danger"
      }
      else {
        let userProfileData = {
          user_id: userRegisterResp.resp_data.user_id,
          user_email: this.userEmail,
          login_process: "email"
        }
        this.$store.dispatch("User/setUserProfileAction", { ...this.userData, ...userProfileData })
        setTimeout(() => {
          this.$router.push("/activate")
        }, 1000)
      }
    }
  }
}
</script>
